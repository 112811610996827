import React from 'react';
import {
  AlertOutlined,
} from '@ant-design/icons';
import './index.css';

const promptList = [
  '“用简单的术语解释量子力学”',
  '“手写一个Promise”',
  '“女朋友过生日，有什么创意礼物推荐吗？”',
];

function TipSection({ onSearch }) {
  return (
    <div className="tip-section">
      <h1>Chat AI</h1>
      <h2>对接官方自然语言GPT模型API</h2>
      <h3><AlertOutlined /><span className="usage-title">使用示例</span></h3>
      <div className="question-list">
        {promptList.map((item, index) => (
          <div
            className="question-item"
            key={index}
            onClick={() => { onSearch(item); }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default TipSection;