import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Input } from 'antd';
import {
  SendOutlined,
} from '@ant-design/icons';
import './App.css';
import ChatSection from './components/ChatSection';
import TipSection from './components/TipSection';

const { Search } = Input;

const baseUri = 'https://gpt-server.jinakeji.com/gpt';

function App() {
  const [uuid, setUuid] = useState('');
  const [prompt, setPrompt] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    try {
      const localChatStr = sessionStorage.getItem('LOCAL_CHAT_MSG');
      if (localChatStr) {
        setChatList(JSON.parse(localChatStr));
        setShowChat(true);
      }
    } catch {}
  }, [])

  useEffect(() => {
    const fetchUuid = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      setUuid(visitorId);
      localStorage.setItem('LOCAL_UUID', visitorId);
    }
    try {
      const localUuid = localStorage.getItem('LOCAL_UUID');
      if (localUuid) {
        setUuid(localUuid)
      } else {
        fetchUuid();
      }
    } catch {
      fetchUuid();
    }
  }, [])

  const onInput = (e) => {
    setPrompt(e.target.value);
  }

  const onSubmit = async (inputValue) => {
    setChatList([...chatList, {
      type: 'question',
      content: inputValue,
    }, {
      type: 'answer',
      content: '',
      prompt: inputValue,
    }]);
    setIsLoading(true);
    setShowChat(true);
    setPrompt('');
    try {
      const result = await axios.get(`${baseUri}/chat?prompt=${inputValue}&uuid=${uuid}`);
      setChatList((chatList) => {
        let newContent = result.data.replace(/^\s+|\s+$/g, '');
        // 去除文本开头标点符号
        newContent = newContent.replace(/^[\uFF00-\uFFFF]+/, '');
        chatList[chatList.length - 1].content = newContent;
        sessionStorage.setItem('LOCAL_CHAT_MSG', JSON.stringify(chatList));
        return chatList;
      });
    } catch (err) {
      console.log('resp err', err);
    } finally {
      setIsLoading(false);
    }
    // 页面滚动到最底部
    document.querySelector('#bottom').scrollIntoView();
  }
  return (
    <div className="App">
      {!showChat ?
        <TipSection onSearch={onSubmit} />
        :
        <ChatSection
          chatList={chatList}
          isLoading={isLoading}
          onRetry={onSubmit}
        />
      }
      <div id="bottom" />
      <div className="input-section">
        <Search
          placeholder="随便问点什么吧~"
          enterButton={<SendOutlined />}
          size="large"
          value={prompt}
          onChange={onInput}
          onSearch={onSubmit}
        />
      </div>
    </div>
  );
}

export default App;
